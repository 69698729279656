import FooterContainer_InnerShadowScreenComponent from '@wix/thunderbolt-elements/src/components/FooterContainer/viewer/skinComps/InnerShadowScreen/InnerShadowScreen.skin';


const FooterContainer_InnerShadowScreen = {
  component: FooterContainer_InnerShadowScreenComponent
};


export const components = {
  ['FooterContainer_InnerShadowScreen']: FooterContainer_InnerShadowScreen
};


// temporary export
export const version = "1.0.0"
